import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
//import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs';
import ReactExport from 'react-export-excel';
// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDeliveryInfo,
  batchUpdateDeliveryInfos,
  batchUpdateDeliveryInfosAllData,
  getAllSalesFromToInDelivery,
} from '../../redux/actions/managerActions';
import { getProducts } from '../../redux/actions/dataActions';
import { isLogged } from '../../redux/actions/userActions';
// components
import CenterLoading from '../../components/layout/CenterLoading';
import logo from '../../logo.svg';
// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Box,
  Paper,
  Typography,
  Divider,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  ArchiveRounded,
  CloseRounded,
  DeleteForeverRounded,
  EditRounded,
  Filter1Rounded,
  Filter2Rounded,
  HelpRounded,
  InputRounded,
  MaximizeRounded,
  MinimizeRounded,
  PrintRounded,
  SaveRounded,
  SearchRounded,
  SaveTwoTone,
} from '@material-ui/icons';

// excel
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styles = (theme) => ({
  ...theme.userPage,
  padding: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  paddingShort: {
    paddingRight: 2,
    paddingLeft: 2,
    paddingTop: 0,
    paddingBottom: 0,
  },
  paddingTopShort: {
    paddingRight: 2,
    paddingLeft: 2,
  },
  btn: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
});
export const AdminDelivery = (props) => {
  const { classes, history } = props;
  //const user = useSelector(state => state.user);
  const { sales, managerLoading } = useSelector((state) => state.manager);
  const { products } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const printRef = useRef();

  //console.log(sales);
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const [fromDate, setFromDate] = useState(
    dayjs().add(-1, 'month').set('date', 26).format('YYYYMMDD')
  );
  const [toDate, setToDate] = useState(
    dayjs().get('date') < 26
      ? dayjs().set('date', 25).format('YYYYMMDD')
      : dayjs().endOf('month').format('YYYYMMDD')
  );
  let numDateFrom = dayjs(fromDate) / 1000;
  let numDateTo = dayjs(toDate).add(1, 'day') / 1000;
  const thisMonth = dayjs(toDate).add(1, 'month').month() + 1; //수업월
  const [searchHakwon, setSearchHakwon] = useState('');

  // Radio, checkbox
  const [inputType, setInputType] = useState('inputAll');
  const [productType, setProductType] = useState('productAll');
  const [withWorkbook, setWithWorkbook] = useState(false);

  // category
  const categories = ['전체', '플레이어', '러너', '기타'];
  const [category, setCategory] = useState('전체');

  // sort
  const [sortType, setSortType] = useState('hNameA');

  // filter (sa.memo는 취소되지 않고, 예약만 전부 없애버린 건, 제외함. 정기결제는 원번호만 가져온다.)
  const filter =
    sales &&
    sales
      .filter((sa) =>
        searchHakwon === '' ? true : sa.hName?.startsWith(searchHakwon)
      )
      .filter(
        (sa) =>
          sa.status === 'paid' &&
          sa.merchant_uid.length === 15 &&
          !sa.memo &&
          ((sa.lessonType === 'trial'
            ? dayjs(sa.lessonDate * 1000) >= dayjs(numDateFrom * 1000) &&
              dayjs(sa.lessonDate * 1000) < dayjs(numDateTo * 1000)
            : false) || // 수업일이 기간내에 있어야..
            (sa.billingDates
              ? dayjs(sa.billingDates[sa.billingDates.length - 1]) / 1000 >=
                numDateTo
              : true))
        /*  (sa.productId === 'JnzBWJx8oTwH9KLcWG0B' || sa.productId === 'CwuUfd5sL2srGqpSxoHZ') ? dayjs(sa.lessonDate * 1000).add(11, 'week') >= dayjs(numDateTo * 1000).add(1, 'month').startOf('month')
                        : (sa.productId === 'DzbNwY2Bz5uPXP5r2sX6' || sa.productId === 'hYkzmnFPqshqRNgr4gCN') ? dayjs(sa.lessonDate * 1000).add(3, 'week') >= dayjs(numDateTo * 1000).add(1, 'month').startOf('month'): false
 */
        // 마지막 수업일이 배송마감일보다 커야 수업가능
      )
      .filter(
        (sa) =>
          (inputType === 'inputAll' && true) ||
          (inputType === 'inputDone' && !!sa.deliNumber) ||
          (inputType === 'inputNone' && !sa.deliNumber)
      )
      .filter(
        (sa) =>
          (productType === 'productAll' && true) ||
          (productType === 'productOneday' && sa.lessonType === 'trial') ||
          (productType === 'productMgo' &&
            (sa.lessonType === 'regular' || sa.lessonType === 'premium'))
      );

  // group By
  const groupByHakwon =
    Array.from(groupBy(filter, (sa) => sa.hName)).length > 0
      ? Array.from(groupBy(filter, (sa) => sa.hName))
          .filter(
            (sa) =>
              sa[1]
                .map((row) => {
                  const isNew = isNewByRow(row, numDateTo);
                  const isLong = !!row.billingDates;
                  return isNew ? 1 : isLong ? 1 : 0;
                })
                .reduce((n, p) => n + p, 0) > 0
          )
          .sort((a, b) =>
            sortType === 'hNameA'
              ? a[0] > b[0]
                ? 1
                : -1
              : sortType === 'hNamaD'
              ? a[0] < b[0]
                ? 1
                : -1
              : sortType === 'countA'
              ? a[1]
                  .map((row) => {
                    const isNew = isNewByRow(row, numDateTo);
                    const isLong = !!row.billingDates;
                    return isNew ? 1 : isLong ? 1 : 0;
                  })
                  .reduce((n, p) => n + p, 0) -
                b[1]
                  .map((row) => {
                    const isNew = isNewByRow(row, numDateTo);
                    const isLong = !!row.billingDates;
                    return isNew ? 1 : isLong ? 1 : 0;
                  })
                  .reduce((n, p) => n + p, 0)
              : sortType === 'countD'
              ? b[1]
                  .map((row) => {
                    const isNew = isNewByRow(row, numDateTo);
                    const isLong = !!row.billingDates;
                    return isNew ? 1 : isLong ? 1 : 0;
                  })
                  .reduce((n, p) => n + p, 0) -
                a[1]
                  .map((row) => {
                    const isNew = isNewByRow(row, numDateTo);
                    const isLong = !!row.billingDates;
                    return isNew ? 1 : isLong ? 1 : 0;
                  })
                  .reduce((n, p) => n + p, 0)
              : null
          )
      : [];

  const excel = groupByHakwon.map((a) => ({ hName: a[0], deliNumber: '' }));
  const totalCountHakwon = groupByHakwon.length;
  const totalMaterial = _.flatten(
    groupByHakwon.map((s) =>
      s[1].filter((row) => {
        const isNew = isNewByRow(row, numDateTo);
        const isLong = !!row.billingDates;
        return isNew ? true : isLong ? true : false;
      })
    )
  );

  const totalCountMaterial = groupByHakwon
    .map((s) =>
      s[1]
        .map((row) => {
          const isNew = isNewByRow(row, numDateTo);
          const isLong = !!row.billingDates;
          return isNew ? 1 : isLong ? 1 : 0;
        })
        .reduce((n, p) => n + p, 0)
    )
    .reduce((ac, cur) => ac + cur, 0);

  const totalCountPlayer = totalMaterial.filter(
    (l) => l.category === '플레이어'
  ).length;
  const totalCountRunner = totalMaterial.filter(
    (l) => l.category === '러너'
  ).length;
  const totalCountETC =
    totalCountMaterial - totalCountPlayer - totalCountRunner;

  const totalCountWorkBook = groupByHakwon
    .map((s) =>
      s[1]
        .map((row, index, arr) => {
          return isDupByRow(row, arr, thisMonth);
        })
        .reduce((n, p) => n + p, 0)
    )
    .reduce((ac, cur) => ac + cur, 0);

  const handleAdminSales = () => {
    const dates = {
      from:
        dayjs(toDate).add(-1, 'year').add(-1, 'month').startOf('month') / 1000, // 이전 기간 수강생 모두 가져오기
      to: numDateTo,
    };
    console.log(dates);
    const res = dispatch(isLogged(history));

    if (res === 'logged') {
      //dispatch(getAllSalesDirectly());

      dispatch(getAllSalesFromToInDelivery(dates));
      dispatch(getProducts());
    }
  };
  // 메인에 있는 delNum
  const [delNum, setDelNum] = useState([]);

  const handleChangeDelNum = (e, ind) => {
    let newDelNum = [...delNum];
    newDelNum[ind] = e.target.value;
    setDelNum(newDelNum);
    setDetailDeliNumber(e.target.value);
  };

  // Radio, checkbox
  const handleInputType = (e) => {
    setInputType(e.target.value);
  };
  const handleProductType = (e) => {
    setProductType(e.target.value);
  };
  const handleWithWorkbook = () => {
    setWithWorkbook(!withWorkbook);
  };

  // sort

  const toggleHName = () => {
    if (sortType === 'hNameD') {
      setSortType('hNameA');
    } else {
      setSortType('hNameD');
    }
  };
  const toggleCount = () => {
    if (sortType === 'countA') {
      setSortType('countD');
    } else {
      setSortType('countA');
    }
  };

  // dialog
  const [detailOpen, setDetailOpen] = useState(false);
  const [details, setDetails] = useState([]);

  const [withoutDone, setWithoutDone] = useState(true); // 워크북중복제외
  const [printMode, setPrintMode] = useState(
    localStorage.printMode ? localStorage.printMode : false
  );
  const [memo, setMemo] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editPid, setEditPid] = useState('');
  const [newDelNum, setNewDelNum] = useState('');

  const [detailDeliNumber, setDetailDeliNumber] = useState('');
  const [detailDeliNumbers, setDetailDeliNumbers] = useState([]);

  // 기본 dialog popup
  const handleViewDetail = (sa, index) => {
    let temp = [...sa];
    temp.map(
      (d) =>
        (d.deliNumber =
          inputType === 'inputDone' ? d.deliNumber : delNum[index])
    );
    setDetailDeliNumbers(
      temp.map(
        (d) =>
          (d.deliNumber =
            inputType === 'inputDone' ? d.deliNumber : delNum[index])
      )
    );
    setDetailDeliNumber(delNum[index]);
    setDetails(temp);
    setDetailOpen(true);

    setMemo('');
  };
  const closeDialog = () => {
    setDetails([]);
    setDetailDeliNumbers([]);
    setDetailDeliNumber('');

    setMemo('');
    setDetailOpen(false);
    setCategory('전체');
  };
  // 학원별 저장
  const saveDeliNumber = (lists, number) => {
    if (lists) {
      if (window.confirm('저장하시겠습니까?')) {
        dispatch(
          batchUpdateDeliveryInfos(lists, number, { from: 0, to: numDateTo })
        );
        setEditDialogOpen(false);
        setDetailOpen(false);
      }
    } else {
      alert('값이 없습니다.');
    }
  };
  // 전체 일괄 저장
  const handleSaveAllData = () => {
    let pIdsAll = [];
    groupByHakwon.map((gr) => {
      let sa = gr[1];
      resul.map((res) =>
        res.hName === gr[0] ? (sa.deliNumber = res.deliNumber) : null
      );
      return sa
        .filter((row) => {
          const isNew = isNewByRow(row, numDateTo);
          const isLong = !!row.billingDates;
          return withoutDone ? isNew || isLong : true;
        })
        .map((row) => {
          return pIdsAll.push({
            pid: row.paymentId,
            num: sa.deliNumber,
          });
        });
    });
    const pids = pIdsAll
      .filter((s) => typeof s.num !== 'undefined')
      .map((s) => s.pid);
    const nums = pIdsAll
      .filter((s) => typeof s.num !== 'undefined')
      .map((s) => s.num);

    if (pids.length > 0 && nums.length > 0) {
      if (window.confirm('일괄 저장하시겠습니까?')) {
        dispatch(
          batchUpdateDeliveryInfosAllData(pids, nums, {
            from: 0,
            to: numDateTo,
          })
        );
      }
    } else {
      alert('송장번호 입력이 필요합니다.');
    }
  };
  const printPage = () => {
    setPrintMode(!printMode);
    localStorage.setItem('printMode', !printMode);
  };
  const editDeliNumber = (pId) => {
    setEditDialogOpen(true);
    setEditPid(pId);
  };
  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setEditPid('');
  };
  const handleDeleteAll = (lists) => {
    if (lists) {
      if (window.confirm('전부 삭제하시겠습니까?')) {
        dispatch(
          batchUpdateDeliveryInfos(lists, '', { from: 0, to: numDateTo })
        );
        setEditDialogOpen(false);
        setDetailOpen(false);
      }
    } else {
      alert('값이 없습니다.');
    }
  };
  const saveEditNumber = () => {
    if (newDelNum) {
      if (window.confirm('저장하시겠습니까?')) {
        dispatch(updateDeliveryInfo(editPid, newDelNum));
        setEditDialogOpen(false);
        setDetailOpen(false);
      }
    } else {
      alert('입력해주세요');
    }
  };

  const [expandMode, setExpandMode] = useState(false);
  const [excelData, setExcelData] = useState('');
  const [resul, setResul] = useState([]);
  const toggleExpand = () => {
    if (filter.length === 0) {
      alert('조회버튼을 누르고 진행하세요.');
    }
    setExpandMode(!expandMode);
  };
  const checkData = () => {
    const raw = excelData.split(' ');
    const resu = raw.map((r) => {
      const hName = r.split('	')[0];
      const dNumber = r.split('	')[1];
      return { hName: hName.trim(), deliNumber: dNumber.trim() };
    });
    setResul(resu);
  };

  const handleInputByCheckData = () => {
    let newDNum = [...delNum];
    groupByHakwon.map((h, index) =>
      resul.map((res) => {
        if (res.hName === h[0]) {
          newDNum[index] = res.deliNumber;
          return setDelNum(newDNum);
        } else {
          return null;
        }
      })
    );
  };

  const detailLists = details
    .filter((row) => {
      const isNew = isNewByRow(row, numDateTo);
      const isLong = !!row.billingDates;
      return withoutDone ? isNew || isLong : true;
    })
    .filter((row) =>
      category === '전체'
        ? true
        : ['플레이어', '러너'].includes(category)
        ? row.category === category
        : !['플레이어', '러너'].includes(row.category)
    ) // 카테고리 분류 기능 추가
    .sort((a, b) => (b.category - a.category > 0 ? 1 : -1));

  //console.log(detailLists);

  const hCount = detailLists.length;
  const h_name = details.length > 0 ? details[0].hName : '';
  const hCountMaterial = details // detailLists
    .map((row) => {
      const isNew = isNewByRow(row, numDateTo);
      const isLong = !!row.billingDates;
      return isNew ? 1 : isLong ? 1 : 0;
    })
    .reduce((n, p) => n + p, 0);
  const hCountWorkbook = details // detailLists
    .map((row, index, arr) => {
      return isDupByRow(row, arr, thisMonth);
    })
    .reduce((n, p) => n + p, 0);
  const hCountPlayer = detailLists.filter(
    (l) => l.category === '플레이어'
  ).length;
  const hCountRunner = detailLists.filter((l) => l.category === '러너').length;
  const hCountETC = hCount - hCountPlayer - hCountRunner;

  // category
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  return (
    <Box p={2} m={2}>
      <Box height={20} />
      <Typography variant="subtitle1" gutterBottom>
        학원별 송장 번호 입력
      </Typography>
      <Divider light />
      <Box m={1} p={1}>
        <TextField
          size="small"
          margin="dense"
          className={classes.date}
          style={{ backgroundColor: '#FAF3E7', width: 90 }}
          required
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <Typography display="inline" className={classes.text}>
          {' '}
          ~{' '}
        </Typography>
        <TextField
          size="small"
          margin="dense"
          style={{ backgroundColor: '#FAF3E7', width: 90 }}
          required
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleAdminSales}
          className={classes.btn}
          startIcon={<SearchRounded />}
        >
          조회
        </Button>
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={toggleExpand}
          className={classes.btn}
          startIcon={<InputRounded />}
        >
          송장번호 일괄입력
        </Button>
        <TextField
          size="small"
          margin="dense"
          className={classes.date}
          style={{ backgroundColor: '#FAF3E7', width: 120, marginLeft: 30 }}
          label={'가맹원'}
          value={searchHakwon}
          onChange={(e) => setSearchHakwon(e.currentTarget.value)}
        />
      </Box>
      {expandMode && (
        <Box m={2} p={2}>
          <ExcelFile
            element={
              <Button
                color="primary"
                size="small"
                variant="outlined"
                startIcon={<ArchiveRounded />}
              >
                Excel 다운
              </Button>
            }
          >
            <ExcelSheet data={excel} name="hakwonlist">
              <ExcelColumn label={'가맹학원명'} value="hName" />
              <ExcelColumn label={'송장번호'} value="deliNumber" />
            </ExcelSheet>
          </ExcelFile>
          <TextField
            label="자료 입력"
            fullWidth
            variant="outlined"
            margin="dense"
            color="secondary"
            value={excelData}
            onChange={(e) => setExcelData(e.target.value)}
          />
          <Typography variant="body2">
            자료입력은 엑셀의 첫열에 '가맹학원명', 둘째열에 '송장번호'를
            띄어쓰기없이 작성하고 두 열에 값이 있는 부분만 선택하여 복사해서
            붙여넣으세요.
          </Typography>
          <Typography variant="body2">
            그리고, (1)check (2)입력을 순서대로 눌러주세요. 송장의 저장은 개별
            학원별로 진행합니다.
          </Typography>
          <Typography variant="body2">
            만일, 아래 라디오 버튼을 변경하면, 리스트가 바뀌므로, 다시 (2)입력을
            눌러줘야 합니다.
          </Typography>
          <Typography variant="body2">
            '일괄DB저장'버튼을 누르시면, 전체가 일시에 저장됩니다. 시간이 좀
            걸리니 기다리시기 바랍니다.
          </Typography>
          <Button
            className={classes.btn}
            variant="contained"
            size="small"
            color="secondary"
            startIcon={<Filter1Rounded />}
            onClick={checkData}
          >
            check
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            size="small"
            color="secondary"
            startIcon={<Filter2Rounded />}
            onClick={handleInputByCheckData}
          >
            입력
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<SaveTwoTone />}
            onClick={handleSaveAllData}
          >
            일괄DB저장
          </Button>
        </Box>
      )}
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="category"
          name="category"
          value={inputType}
          onChange={handleInputType}
          row
        >
          <FormControlLabel
            value={'inputAll'}
            control={<Radio size="small" />}
            label={<Typography variant="body2">입력</Typography>}
          />
          <FormControlLabel
            value={'inputDone'}
            control={<Radio size="small" />}
            label={<Typography variant="body2">입력완료건 조회시</Typography>}
          />
          <FormControlLabel
            value={'inputNone'}
            control={<Radio size="small" />}
            label={<Typography variant="body2">미입력건</Typography>}
          />
        </RadioGroup>
      </FormControl>
      <br />
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="category"
          name="category"
          value={productType}
          onChange={handleProductType}
          row
        >
          <FormControlLabel
            value={'productAll'}
            control={<Radio size="small" />}
            label={<Typography variant="body2">전체</Typography>}
          />
          <FormControlLabel
            value={'productOneday'}
            control={<Radio size="small" />}
            label={<Typography variant="body2">원데이클래스</Typography>}
          />
          <FormControlLabel
            value={'productMgo'}
            control={<Radio size="small" />}
            label={
              <Typography variant="body2">뮤직고고/뮤고프리미엄</Typography>
            }
          />
        </RadioGroup>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={withWorkbook}
            onChange={handleWithWorkbook}
            name="checked"
          />
        }
        label={
          withWorkbook ? (
            <Typography variant="body2">
              워크북 표시(3,6,9,12월+신규)
            </Typography>
          ) : (
            <Typography variant="body2">워크북 미표시</Typography>
          )
        }
      />

      {managerLoading ? (
        <CenterLoading />
      ) : (
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.padding}>번호</TableCell>
                <TableCell className={classes.padding}>
                  <Button
                    color="primary"
                    onClick={toggleHName}
                    endIcon={
                      sortType === 'hNameD' ? (
                        <ArrowDropDown color="primary" />
                      ) : (
                        sortType === 'hNameA' && (
                          <ArrowDropUp color="secondary" />
                        )
                      )
                    }
                  >
                    학원명
                  </Button>
                </TableCell>
                {/* <TableCell className={classes.padding}>배송주소</TableCell> */}
                <TableCell className={classes.padding}>구매품목</TableCell>
                <TableCell className={classes.padding}>
                  <Button
                    color="primary"
                    onClick={toggleCount}
                    endIcon={
                      sortType === 'countD' ? (
                        <ArrowDropDown color="primary" />
                      ) : (
                        sortType === 'countA' && (
                          <ArrowDropUp color="secondary" />
                        )
                      )
                    }
                  >
                    교구
                  </Button>
                </TableCell>
                <TableCell className={classes.padding}>
                  뮤고 송장 일괄입력
                  <Tooltip title="원데이클래스는 상세내역에서 송장번호를 확인하시기 바랍니다">
                    <IconButton aria-label="help" size="small">
                      <HelpRounded />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.padding}>상세내역</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupByHakwon.map((sa, index) => {
                const h = sa[0];
                const s = sa[1];
                //const addr = s[0].buyer_addr;

                ///
                const tempList = s.filter((row) => {
                  const isNew = isNewByRow(row, numDateTo);
                  const isLong = !!row.billingDates;
                  return withoutDone ? isNew || isLong : true;
                });
                const allCnt = tempList.length;
                const allPlayerCnt = tempList.filter(
                  (l) => l.category === '플레이어'
                ).length;
                const allRunnerCnt = tempList.filter(
                  (l) => l.category === '러너'
                ).length;
                const allETCCnt = allCnt - allPlayerCnt - allRunnerCnt;

                const tempWorkBookCount = s
                  .map((row, index, arr) => {
                    return isDupByRow(row, arr, thisMonth);
                  })
                  .reduce((n, p) => n + p, 0);

                const shortNames = tempList.map((t) => {
                  const tempP = products.filter(
                    (prod) => prod.productId === t.productId
                  );
                  return tempP.length > 0 ? tempP[0].shortName : '';
                });

                const names = Array.from(groupBy(shortNames, (name) => name));

                const lists = names.map((v, i) => (
                  <Typography variant="body2" key={i}>
                    ㆍ{v[0]} : {v[1].length}
                  </Typography>
                ));
                const cnt = (
                  <div>
                    <Typography variant="body2" color="secondary">
                      <strong>[총 {allCnt} 건]</strong>
                    </Typography>
                    <Typography variant="body2">
                      ㆍ플레이어: {allPlayerCnt}건
                    </Typography>
                    <Typography variant="body2">
                      ㆍ러너 : {allRunnerCnt}건
                    </Typography>
                    <Typography variant="body2">
                      ㆍ기타 : {allETCCnt}건
                    </Typography>
                    <Typography variant="body2">
                      {withWorkbook && `ㆍ워크북 총 ${tempWorkBookCount}권`}
                    </Typography>
                  </div>
                );
                const invoice = (
                  <Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      //label='송장번호'
                      margin="dense"
                      style={{ width: 150 }}
                      value={delNum[index]}
                      onChange={(e) => handleChangeDelNum(e, index)}
                    />

                    {/* <Button size='small' variant='contained' color='primary' className={classes.btn}>입력</Button> */}
                  </Box>
                );
                const detailBtn = (
                  <Button
                    color="primary"
                    onClick={() => handleViewDetail(s, index)}
                  >
                    상세 내역
                  </Button>
                );

                return (
                  <TableRow key={index}>
                    <TableCell className={classes.padding}>
                      {index + 1}
                    </TableCell>
                    <TableCell className={classes.padding}>{h}</TableCell>
                    {/* <TableCell className={classes.padding} width={250}>
                                            {addr}
                                        </TableCell> */}
                    <TableCell className={classes.padding}>{lists}</TableCell>
                    <TableCell className={classes.padding}>{cnt}</TableCell>
                    <TableCell className={classes.padding}>{invoice}</TableCell>
                    <TableCell className={classes.padding}>
                      {detailBtn}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow style={{ backgroundColor: '#fee' }}>
                <TableCell className={classes.padding}></TableCell>
                <TableCell className={classes.padding}>
                  총 {totalCountHakwon}개원
                </TableCell>
                <TableCell className={classes.padding}></TableCell>
                <TableCell className={classes.padding}></TableCell>
                <TableCell className={classes.padding}>
                  <div>
                    <Typography variant="body2" color="primary">
                      <strong>[교구 총 {totalCountMaterial} 건]</strong>
                    </Typography>
                    <Typography variant="body2">
                      ㆍ플레이어: {totalCountPlayer}건
                    </Typography>
                    <Typography variant="body2">
                      ㆍ러너 : {totalCountRunner}건
                    </Typography>
                    <Typography variant="body2">
                      ㆍ기타 : {totalCountETC}건
                    </Typography>
                    <Typography variant="body2">
                      {withWorkbook && `ㆍ워크북 총 ${totalCountWorkBook}권`}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.padding}></TableCell>
                <TableCell className={classes.padding}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box height={100} />

      {/* 학원 별 상세팝업 */}
      <Dialog open={detailOpen} fullWidth maxWidth={'md'}>
        <DialogTitle> {h_name} 배송리스트</DialogTitle>
        <Box align="center">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="category"
              name="category"
              value={category}
              onChange={handleCategory}
              row
            >
              {categories.map((cate, ind) => (
                <Box key={ind}>
                  <FormControlLabel
                    value={cate}
                    control={<Radio />}
                    label={<Typography>{cate}</Typography>}
                  />
                  <Box height={20} />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={withoutDone}
                onChange={() => setWithoutDone(!withoutDone)}
                name="checked"
              />
            }
            label={
              withoutDone ? (
                <Typography variant="body2">수강종료 제외</Typography>
              ) : (
                <Typography variant="body2">수강종료 포함</Typography>
              )
            }
          />
        </Box>
        <DialogContent ref={printRef}>
          <Box style={{ position: 'absolute', right: 10, top: 10 }}>
            <img src={logo} width="84px" height="40px" alt="logo" />
          </Box>

          <Typography variant="h4">
            [ {h_name} {thisMonth}월수업 뮤직고고 교구/교재 배송 내역 ]
          </Typography>

          <Typography>ㆍ송장번호(CJ대한통운) : {detailDeliNumber} </Typography>

          <Box>
            <Box height={10} />
            <Box
              style={{
                backgroundColor: '#fee',
                paddingLeft: 20,
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <Typography variant="h5">{category}</Typography>
            </Box>
            <Box height={10} />
          </Box>

          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.paddingTopShort}>No</TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    주문번호
                  </TableCell>

                  <TableCell className={classes.paddingTopShort}>
                    결제일
                  </TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    수업시작
                  </TableCell>

                  <TableCell className={classes.paddingTopShort}>
                    수업
                  </TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    상태
                  </TableCell>

                  <TableCell className={classes.paddingTopShort}>
                    유형
                  </TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    구분
                  </TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    원생
                  </TableCell>
                  <TableCell className={classes.paddingTopShort}>
                    교구
                  </TableCell>
                  {withWorkbook && (
                    <TableCell className={classes.paddingTopShort}>
                      워크북
                    </TableCell>
                  )}

                  {printMode ? null : (
                    <TableCell className={classes.paddingTopShort}>
                      송장번호 전체
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteAll(detailLists.map((d) => d.paymentId))
                        }
                      >
                        <DeleteForeverRounded />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {detailLists.map((row, index) => {
                    const isNew = isNewByRow(row, numDateTo);
                    const isLong = !!row.billingDates;
                    const dup = isDupByRow(row, details, thisMonth);

                    const inputNumber = (
                      <TextField
                        size="small"
                        margin="dense"
                        style={{ width: 180 }}
                        value={detailDeliNumbers[index]}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                onClick={() => editDeliNumber(row.paymentId)}
                              >
                                <EditRounded />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    );

                    return (
                      <TableRow key={row.paymentId}>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                          align="left"
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                        >
                          <Typography variant="subtitle2">
                            {row.paymentId}
                          </Typography>
                        </TableCell>

                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                          width="100px"
                        >
                          {dayjs(row.paid_at * 1000).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {row.lessonDate
                            ? dayjs(row.lessonDate * 1000).format('MM/DD(dd)')
                            : ''}
                        </TableCell>
                        {/* <TableCell size='small' className={classes.paddingShort} width='20%'>{row.name}</TableCell> */}

                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                          align="left"
                        >
                          {dayjs(row.lessonDate * 1000).get('date') > 20 &&
                          row.lessonType === 'regular'
                            ? String(
                                dayjs(row.lessonDate * 1000)
                                  .add(1, 'month')
                                  .get('month') + 1
                              ) + '월'
                            : String(
                                dayjs(row.lessonDate * 1000).get('month') + 1
                              ) + '월'}
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {row.status === 'paid' ? (
                            '결제'
                          ) : row.status === 'cancelled' &&
                            row.cancel_amount < row.amount ? (
                            <Typography color="error">부분취소</Typography>
                          ) : (
                            <Typography color="error">취소</Typography>
                          )}
                        </TableCell>

                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {row.totalRound
                            ? row.totalRound + '개월'
                            : row.lessonType === 'trial'
                            ? '원데이'
                            : row.lessonType === 'premium'
                            ? '프리미엄'
                            : row.imp_uid.substring(0, 5) === 'imps_'
                            ? String(row.paymentId.split('_')[3]) + '개월'
                            : row.lessonType === 'regular' &&
                              row.amount === 45000
                            ? '3개월'
                            : row.lessonType === 'regular' &&
                              row.amount === 15000
                            ? '1개월'
                            : '-'}
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {row.category}
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          <strong>{row.buyer_name}</strong>
                        </TableCell>
                        <TableCell
                          size="small"
                          className={classes.paddingShort}
                        >
                          {isNew ? (
                            '교구발송'
                          ) : isLong ? (
                            '교구발송'
                          ) : (
                            <Typography color="error" variant="body2">
                              종료
                            </Typography>
                          )}
                        </TableCell>
                        {withWorkbook && (
                          <TableCell
                            size="small"
                            className={classes.paddingShort}
                          >
                            {dup > 0 ? (
                              '워크북'
                            ) : (
                              <Typography color="error" variant="body2">
                                기지급
                              </Typography>
                            )}
                          </TableCell>
                        )}
                        {printMode ? null : (
                          <TableCell
                            size="small"
                            className={classes.paddingShort}
                          >
                            {inputNumber}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {/* 합계 */}
                  <TableRow style={{ backgroundColor: '#fee' }}>
                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                    ></TableCell>
                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                      align="center"
                    >
                      {category === '전체' && '플레이어: ' + hCountPlayer + ','}
                    </TableCell>

                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                      align="center"
                    >
                      {category === '전체' && '러너: ' + hCountRunner + ','}
                    </TableCell>
                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                      align="center"
                    >
                      {category === '전체' && '기타: ' + hCountETC}
                    </TableCell>

                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                    ></TableCell>
                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                    ></TableCell>
                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                    ></TableCell>

                    <TableCell
                      size="small"
                      className={classes.paddingTopShort}
                    ></TableCell>
                    <TableCell size="small" className={classes.paddingTopShort}>
                      {hCount}명
                    </TableCell>
                    <TableCell size="small" className={classes.paddingTopShort}>
                      <strong>{numberFormat(hCount)}건</strong>
                    </TableCell>
                    {withWorkbook && (
                      <TableCell
                        size="small"
                        className={classes.paddingTopShort}
                      >
                        <strong>{numberFormat(hCountWorkbook)}건</strong>
                      </TableCell>
                    )}
                    {printMode ? null : (
                      <TableCell
                        size="small"
                        className={classes.paddingTopShort}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableBody>
              }
            </Table>
          </TableContainer>
          <form noValidate autoComplete="off">
            <TextField
              label="memo"
              size="small"
              fullWidth
              multiline
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Typography variant="subtitle2">
            전체교구 : {numberFormat(hCountMaterial)} 건{' '}
            {category === '전체' &&
              ' [플레이어: ' +
                hCountPlayer +
                ', 러너: ' +
                hCountRunner +
                ', 기타: ' +
                hCountETC +
                ']'}
          </Typography>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={printPage}
            startIcon={!printMode ? <MinimizeRounded /> : <MaximizeRounded />}
          >
            {!printMode ? '좁게' : '넓게'}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                startIcon={<PrintRounded />}
              >
                프린트
              </Button>
            )}
            content={() => printRef.current}
          />

          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            color="primary"
            onClick={() =>
              saveDeliNumber(
                detailLists.map((sa) => sa.paymentId),
                detailDeliNumber
              )
            }
            startIcon={<SaveRounded />}
          >
            송장번호 저장
          </Button>
          <Button
            variant="outlined"
            onClick={closeDialog}
            startIcon={<CloseRounded />}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} fullWidth maxWidth="xs">
        <DialogContent>
          <TextField
            label="새 송장번호"
            value={newDelNum}
            onChange={(e) => setNewDelNum(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={saveEditNumber}
            size="small"
            startIcon={<SaveRounded />}
          >
            저장
          </Button>
          <Button
            variant="contained"
            onClick={closeEditDialog}
            size="small"
            startIcon={<CloseRounded />}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default withStyles(styles)(AdminDelivery);

// groupBy
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
function numberFormat(inputNumber) {
  if (!inputNumber) return 0;
  if (typeof inputNumber === 'number') {
    inputNumber = inputNumber.toString();
  }
  return inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function isNewByRow(row, numTo) {
  // 1개월이거나, 3개월선결제일경우 해당여부 (1개월은 수업월과 당월이 같아야하고, 3개월은 수업월 2달+ 보다 당월이 같거나 작아야함)
  const lessonMonth =
    dayjs(row.lessonDate * 1000).get('date') > 20 &&
    row.lessonType === 'regular'
      ? dayjs(row.lessonDate * 1000).add(1, 'month')
      : dayjs(row.lessonDate * 1000);

  const lesson3Month =
    dayjs(row.lessonDate * 1000).get('date') > 20 &&
    row.lessonType === 'regular'
      ? dayjs(row.lessonDate * 1000).add(3, 'month')
      : dayjs(row.lessonDate * 1000).add(2, 'month');

  if (
    row.productId === 'DzbNwY2Bz5uPXP5r2sX6' ||
    row.productId === 'hYkzmnFPqshqRNgr4gCN'
  ) {
    return dayjs(numTo * 1000)
      .add(1, 'month')
      .isSame(lessonMonth, 'month');
  } else if (
    row.productId === 'JnzBWJx8oTwH9KLcWG0B' ||
    row.productId === 'CwuUfd5sL2srGqpSxoHZ'
  ) {
    return dayjs(numTo * 1000).isBefore(lesson3Month, 'month');
  }
}

function isDupByRow(row, arr, thisMonth) {
  // 워크북은 3개월마다 지급함 (3,6,9,12월)
  const isWSMonth = [3, 6, 9, 12].indexOf(thisMonth) > -1;
  // 정규수업이면, 이름+생년월일 기준으로 중복확인.
  const isDup =
    arr
      .filter(
        (sa) => sa.lessonType === 'regular' || sa.lessonType === 'premium'
      )
      .map((sa) => sa.buyer_name.trim() + sa.age.trim())
      .filter((x) => x === row.buyer_name.trim() + row.age.trim()).length > 1;
  // 레슨시작월이 현재월이면 신규지급 (25일이후생성하면, 다음달로 봄)
  const wbMonth =
    (dayjs(row.lessonDate * 1000).get('date') < 25
      ? dayjs(row.lessonDate * 1000).month() + 1
      : dayjs(row.lessonDate * 1000).month() + 2) === thisMonth
      ? 1
      : 0;

  return isWSMonth ? 1 : isDup ? 0 : wbMonth ? 1 : 0;
}
